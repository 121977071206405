import { SET_USER } from '../actions/action-types';

const initialState = {
  user: {}
}

function rootReducer(state = initialState, action) {
  if (action.type === SET_USER) {
    const newState = Object.assign({}, state, {
      user: Object.assign({}, action.payload)
    });
    console.log(' new state: ', newState);
    return newState;
  }
  return state;
}

export default rootReducer;