import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import Storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import rootReducer from "./reducers";

const persistConfig = {
  key: 'root',
  storage: Storage,
  stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const Store = createStore(pReducer);
export const Persistor = persistStore(Store);