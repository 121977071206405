import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import "./i18n";
import * as serviceWorker from './serviceWorker';
import { Store, Persistor } from './app/store'
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <Provider store={Store}>
    <PersistGate persistor={Persistor}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
, document.getElementById('root'));

serviceWorker.unregister();