import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { connect } from "react-redux";
import {setUser} from "../../store/actions";

class SideBar extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
  }


  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }

  getUsername() {
    return this.props.userApp.name;
  }

  logOut(event) {
    event.preventDefault();
    this.props.setUserState({});
    this.props.history.push('/login');
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/advanced-ui', state: 'advancedUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/maps', state: 'mapsMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
      {path:'/general-pages', state: 'generalPagesMenuOpen'},
      {path:'/ecommerce', state: 'ecommercePagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));

  }
  render () {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-category"><Trans>Main</Trans></li>
          <li className={ this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard">
              <span className="icon-bg"><i className="mdi mdi-cube menu-icon"></i></span>
              <span className="menu-title"><Trans>Dashboard</Trans></span>
            </Link>
          </li>


          <li className="nav-item nav-category"><Trans>Users</Trans></li>
          {
            this.props.userApp.scope === 'admin' ?
              (
              <li className={ this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item' }>
                <div className={ this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
                  <span className="icon-bg"><i className="mdi mdi-contacts menu-icon"></i></span>
                  <span className="menu-title"><Trans>Users Management</Trans></span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={ this.state.basicUiMenuOpen }>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <Link className={ this.isPathActive('/users/add') ? 'nav-link active' : 'nav-link' } to="/users/add"><Trans>Add user</Trans></Link></li>
                    <li className="nav-item"> <Link className={ this.isPathActive('/users/listing') ? 'nav-link active' : 'nav-link' } to="/users/listing"><Trans>User Listing</Trans></Link></li>
                    <li className="nav-item"> <Link className={ this.isPathActive('/users/verify/handyman') ? 'nav-link active' : 'nav-link' } to="/users/verify/handyman"><Trans>Verify Handyman</Trans></Link></li>
                  </ul>
                </Collapse>
              </li>)
            : null
          }

          {
            this.props.userApp.scope === 'admin' ?
              (
                <li className={ this.isPathActive('/companies') ? 'nav-item active' : 'nav-item' }>
                  <div className={ this.state.CompaniesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('CompaniesMenuOpen') } data-toggle="collapse">
                    <span className="icon-bg"><i className="mdi mdi-home-modern menu-icon"></i></span>
                    <span className="menu-title"><Trans>Companies Mgmt.</Trans></span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={ this.state.CompaniesMenuOpen }>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item"> <Link className={ this.isPathActive('/companies/add') ? 'nav-link active' : 'nav-link' } to="/companies/add"><Trans>Add Company</Trans></Link></li>
                    </ul>
                  </Collapse>
                </li>
              )
              : null
          }

          <li className={ this.isPathActive('/icons') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('iconsMenuOpen') } data-toggle="collapse">
              <span className="icon-bg"><i className="mdi mdi-animation menu-icon"></i></span>
              <span className="menu-title"><Trans>Requests</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.iconsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/requests/listing') ? 'nav-link active' : 'nav-link' } to="/requests/listing"><Trans>Listing</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/requests/manual') ? 'nav-link active' : 'nav-link' } to="/requests/manual"><Trans>Manual Request</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/requests/services') ? 'nav-link active' : 'nav-link' } to="/requests/services"><Trans>Services</Trans></Link></li>
              </ul>
            </Collapse>
          </li>



          <li className="nav-item sidebar-user-actions">
            <div className="user-details">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <div className="d-flex align-items-center">
                    <button type="button" className="btn btn-sm btn-success btn-rounded btn-icon ">
                      <i className="mdi mdi-account" />
                    </button>
                    <div className="sidebar-profile-text">
                      <p className="mb-1" ><Trans>{ this.getUsername() }</Trans></p>
                    </div>
                  </div>
                </div>
                <div className="badge badge-danger">3</div>
              </div>
            </div>
          </li>
          <li className="nav-item sidebar-user-actions">
            <div className="sidebar-user-menu">
              <a href="!#" onClick={event => event.preventDefault()} className="nav-link"><i className="mdi mdi-settings menu-icon"></i>
                <span className="menu-title"><Trans>Settings</Trans></span>
              </a>
            </div>
          </li>
          <li className="nav-item sidebar-user-actions">
            <div className="sidebar-user-menu">
              <a href="!#" onClick={event => event.preventDefault()} className="nav-link"><i className="mdi mdi-speedometer menu-icon"></i>
                <span className="menu-title"><Trans>Take Tour</Trans></span></a>
            </div>
          </li>
          <li className="nav-item sidebar-user-actions">
            <div className="sidebar-user-menu">
              <a href="!#" onClick={ this.logOut } className="nav-link"><i className="mdi mdi-logout menu-icon"></i>
                <span className="menu-title"><Trans>Log Out</Trans></span></a>
            </div>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

const mapStateToProps = (state) => {
  return {
    userApp: state.user
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUserState: user => dispatch(setUser(user))
  };
}

const ComponentWithRoute = withRouter(SideBar);
const SideBarComponent = connect(mapStateToProps, mapDispatchToProps)(ComponentWithRoute);
export default SideBarComponent;