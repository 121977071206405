import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/components/BigSpinner';

const Dashboard = lazy(() => import('./views/Dashboard'));
const UserListing = lazy(() => import('./views/Users/UserListing'));
const VerifyHandyman = lazy(() => import('./views/Users/VerifyHandyman'));
const CreateUser = lazy( () => import('./views/Users/CreateUser') );
const CreateCompany = lazy( () => import('./views/Companies/AddCompany') );

const RequestListing = lazy( () => import('./views/Requests/RequestListing') );
const ManualRequest = lazy( () => import('./views/Requests/ManualRequest') );
const RequestServices = lazy( () => import('./views/Requests/RequestServices') );
const RequestDetails = lazy( () => import('./views/Requests/RequestDetails') );
const RequestQuote = lazy( () => import('./views/Requests/RequestQuote') );

const Login = lazy(() => import('./views/Login'));


class AppRoutes extends Component {

  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/login" component={ Login } />
          <Route path="/dashboard" component={ Dashboard } />

          <Route path="/companies/add" component={ CreateCompany }/>

          <Route path="/users/listing" component={ UserListing } />
          <Route path="/users/verify/handyman" component={ VerifyHandyman } />
          <Route path="/users/add" component={ CreateUser } />

          <Route path="/requests/listing" component={ RequestListing } />
          <Route path="/requests/manual" component={ ManualRequest } />
          <Route path="/requests/services" component={ RequestServices } />
          <Route path="/requests/:id/details" component={ RequestDetails } />
          <Route path="/requests/:id/quote" component={ RequestQuote } />

          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );

  }
}

export default AppRoutes;